








































































































































































































































































































































































































































































































































































































import Component, {mixins} from 'vue-class-component';
import DataFormat from '@/shared/utils/dataFormat';
import {Prop} from 'vue-property-decorator';
import RefundPolicyList from '@/components/list/RefundPolicyList.vue';

@Component({ components: { RefundPolicyList } })
export default class PaymentInfo extends mixins(DataFormat) {
  @Prop({
    required: true
  })
  public isPopup!:boolean;

  @Prop({
    required: true
  })
  public reservation!: any;

  @Prop()
  public selectLang!: any;

  public pgText(data: string) {
    if (data === 'naver_pay') {
      return '네이버 페이';
    } else if (data === 'kakao_pay') {
      return '카카오 페이';
    } else if (data === 'smile_pay') {
      return '스마일 페이'
    } else if (data === 'paypal') {
      return '페이팔'
    } else if (data === 'paymentwall') {
      return '해외카드'
    }
  }
  // 환불정보 비노출 조건 (결제요청, 결제기한만료)
  public cancelDisplayStyle(status: string) {
    if (
      status === 'wait' ||
      status === 'expire'
    ) {
      return 'display: none;';
    }
    return '';
  }

  // 현재 결제 해야되는 금액 및 기한
  get totalPrice() {
    const data: any = {
      price: 0,
      price_usd: 0,
      date: ''
    };
    for (const pay of this.reservation.deposit) {
      if (!pay.paid_at) {
        data.price = pay.price;
        data.price_usd = pay.price_usd;
        data.date = pay.payment_limit_at;
      }
    }
    return data;
  }
  // 차수별 환불 수수료 다를 경우
  public refundCommissionN(type?: 'USD') {
    let total: any = {
      price: 0,
      price_usd: 0
    };
    const lastDeposit: any = this.reservation.deposit[this.reservation.deposit.length - 1];
    let paidPrice = lastDeposit.paid_price;
    let paidRefundPrice = lastDeposit.refund_price;
    if (
      lastDeposit.paid_price === 0 &&
      lastDeposit.paid_price_usd !== 0
    ) {
      paidPrice = lastDeposit.paid_price_usd;
      paidRefundPrice = lastDeposit.refund_price_usd;
    }
    for (let i = 0; i <  this.reservation.deposit.length; i++) {
      let percent: any =
        (100 - this.refundPercent(this.reservation, this.reservation.deposit[i])) / 100;
      if (
        lastDeposit &&
        (paidPrice === paidRefundPrice) &&
        i === this.reservation.deposit.length - 1
      ) {
        percent = 0;
      }
      if (
        this.reservation.deposit[i].payment_status === 'complete' ||
        this.reservation.deposit[i].payment_status === 'refund'
      ) {
        if (
          this.reservation.deposit[i].paid_price === 0 &&
          this.reservation.deposit[i].paid_price_usd !== 0
        ) {
          total.price_usd += this.reservation.deposit[i].paid_price_usd * percent;
        } else {
          total.price += this.reservation.deposit[i].paid_price * percent;
        }
      }
    }
    if (type === 'USD') {
      return total.price_usd;
    }
    return total.price;
  }

  // 환불 수수료
  public refundCommission(type?: 'USD') {
    let percent: any =
      (100 - this.refundPercent(this.reservation, this.paymentDepositList[0])) / 100;
    const lastDeposit: any = this.paymentDepositList[this.paymentDepositList.length - 1];
    if (!lastDeposit) {
      return 0;
    }
    let paidPrice = lastDeposit.paid_price;
    let paidRefundPrice = lastDeposit.refund_price;
    let total: any = {
      price: 0,
      price_usd: 0
    };
    if (
      lastDeposit.paid_price === 0 &&
      lastDeposit.paid_price_usd !== 0
    ) {
      paidPrice = lastDeposit.paid_price_usd;
      paidRefundPrice = lastDeposit.refund_price_usd;
    }
    if (
      lastDeposit &&
      paidPrice === paidRefundPrice
    ) {
      percent = 0;
    }
    for (const pay of this.paymentDepositList) {
      if (
        pay.payment_status === 'complete' ||
        pay.payment_status === 'refund'
      ) {
        if (
          pay.paid_price === 0 &&
          pay.paid_price_usd !== 0
        ) {
          total.price_usd += pay.paid_price_usd;
        } else {
          total.price += pay.paid_price;
        }
      }
    }
    if (type === 'USD') {
      console.log(total.price_usd * percent);
      return Number(total.price_usd * percent);
    }
    console.log(Number(total.price * percent));
    return Number(total.price * percent);
  }

  public renderPrice(price: number, type?: string) {
    if (type === 'USD') {
      return `$${this.NumberFormat(price)}`;
    }
    return `${this.NumberFormat(price)}원`;
  }

  // 총 환불 금액
  get totalRefundPrice() {
    let total: any = {
      price: 0,
      price_usd: 0
    };
    for (const pay of this.paymentDepositList) {
      if (
        pay.payment_status === 'complete' ||
        pay.payment_status === 'refund'
      ) {
        if (
          pay.paid_price === 0 &&
          pay.paid_price_usd !== 0
        ) {
          total.price_usd += pay.paid_price_usd;
        } else {
          total.price += pay.paid_price;
        }
      }
    }
    if (this.cancelPercentDiff) {
      return {
        price: +(total.price - this.refundCommissionN()).toFixed(12),
        price_usd: +(total.price_usd - this.refundCommissionN('USD')).toFixed(12)
      }
    }
    return {
      price: +(total.price - this.refundCommission()).toFixed(12),
      price_usd: +(total.price_usd - this.refundCommission('USD')).toFixed(12)
    }
  }

  // 예약 변경, 취소 안내 문구 노출
  get reserveInfoValidation() {
    if ((this.status !== 'canceled' && this.status !== 'visited') || this.isPopup) {
      return true;
    } else {
      return false;
    }
  }

  // 예약 변경 버튼 노출
  get reserveChangeValidation() {
    const now = this.$moment();
    const startDate = this.$moment(new Date(this.reservation.reserv_start));
    const result = startDate.diff(now, 'days');

    if (this.status !== 'visited' && this.status !== 'canceled' && this.status !== 'popup' && this.status !== 'default') {
      if (this.reservation.deposit_policy.change_able && now.isBefore(startDate)) {
        if (this.reservation.deposit_policy.change_policy.date <= result || this.reservation.deposit_policy.change_policy.time <= result) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  // 상태별 status
  get status() {
    if (this.reservation.reserv_status !== 'canceled' && this.isPopup) {
      return 'popup';
    } else if (this.reservation.reserv_visit_status === 'noshow' || this.reservation.reserv_status === 'not_available') {
      return 'canceled';
    } else if (this.reservation.reserv_visit_status === 'appeared') {
      return 'visited';
    } else if (this.reservation.reserv_status === 'reserving' || this.reservation.reserv_status === ' wait_confirm' || this.$route.name === 'DepositMain') {
      return 'default';
    } else {
      return this.reservation.reserv_status;
    }
  }
  // 취소 수수료 차수별 다른지 판단
  get cancelDiff() {
    let re = false;
    if (this.paymentDepositList.length > 1) {
      for (let i = 0; i <= this.paymentDepositList.length - 1; i++) {
        if (
          this.paymentDepositList[i] &&
          this.paymentDepositList[i].cancel_policy &&
          this.paymentDepositList[i + 1] &&
          this.paymentDepositList[i + 1].cancel_policy
        ) {
          if (
            JSON.stringify(this.paymentDepositList[i].cancel_policy.list) ===
            JSON.stringify(this.paymentDepositList[i + 1].cancel_policy.list)
          ) {
            re = false;
          } else {
            re = true;
            return re;
          }
        }
      }
    }
    return re;
  }
  // 취소 수수료 퍼센트 다른지 판단
  get cancelPercentDiff() {
    let re = false;
    const list = this.paymentDepositList.filter((row: any) => {
      return row.payment_status === 'complete' || row.payment_status === 'refund';
    });
    if (list.length > 1) {
      for (let i = 0; i < list.length - 1; i++) {
        if (
          this.status !== 'popup'
        ) {
          if (
            (list[i].paid_price === 0 &&
            list[i].paid_price_usd !== 0) &&
            (list[i + 1].paid_price !== 0 &&
            list[i + 1].paid_price_usd === 0) ||
            (list[i].paid_price !== 0 &&
            list[i].paid_price_usd === 0) &&
            (list[i + 1].paid_price === 0 &&
            list[i + 1].paid_price_usd !== 0)
          ) {
            console.log('9999');
            re = true;
            return re;
          }
          if (
              this.refundPercent(this.reservation, list[i]) ===
              this.refundPercent(this.reservation, list[i + 1])
          ) {
            const lastDeposit = list[list.length - 1];
            let nextDepositRefund = this.refundPercent(this.reservation, list[i + 1]);
            if (lastDeposit.price === lastDeposit.refund_price) {
              nextDepositRefund = 100;
            }
            if (
              list.length - 1 === i + 1 &&
              nextDepositRefund === 100 &&
              this.refundPercent(this.reservation, list[i]) !== 100
            ) {
              console.log('888');
              re = true;
              return re;
            }
            re = false;
          } else {
            console.log(this.refundPercent(this.reservation, list[i]));
            console.log(this.refundPercent(this.reservation, list[i + 1]));
            console.log('777');
            re = true;
            return re;
          }
        } else {
          if (
            this.paymentDepositList[i] &&
            this.paymentDepositList[i].cancel_policy &&
            this.paymentDepositList[i + 1] &&
            this.paymentDepositList[i + 1].cancel_policy
          ) {
            if (
                this.completeRefundPercent(this.paymentDepositList[i]) ===
                this.completeRefundPercent(this.paymentDepositList[i + 1])
            ) {
              const lastDeposit = this.paymentDepositList[this.paymentDepositList.length - 1];
              let nextDepositRefund = this.completeRefundPercent(this.paymentDepositList[i + 1]);
              if (lastDeposit.price === lastDeposit.refund_price) {
                nextDepositRefund = 100;
              }
              if (
                this.paymentDepositList.length - 1 === i + 1 &&
                nextDepositRefund === 100 &&
                this.completeRefundPercent(this.paymentDepositList[i]) !== 100
              ) {
                re = true;
                return re;
              }
              re = false;
            } else {
              re = true;
              return re;
            }
          }
        }
      }
    }
    return re;
  }
  // 보증금 중 결제완료 상태 체크
  get depositStatusComplete() {
    const deposit = this.reservation.deposit;
    if (deposit) {
      return deposit.map((row: any) => {
        if (row.payment_status === 'complete') {
          return true;
        }
        return false;
      });
    }
    return false;
  }
  // 결제완료한 보증금 리스트
  get paymentDepositList() {
    const re: any = [];
    this.reservation.deposit.filter((row: any) => {
      if (row.payment.pg_type) {
        re.push(row);
      }
    });
    return re;
  }
  // 결제완료한 보증금 리스트 중 환불 안된 리스트
  get notRefundDepositList() {
    const re: any = [];
    this.paymentDepositList.filter((row: any) => {
      if (row.payment_status !== 'refund') {
        re.push(row);
      }
    });
    return re;
  }
  // 결제완료한 보증금 리스트 중 환불 안된 리스트에서 결제한 타입 - 원, 달러, 원/달러
  get notRefundPriceType() {
    let won: any = 0;
    let doller: any = 0;
    this.notRefundDepositList.filter((row: any) => {
      won = won + row.paid_price;
      doller = doller + row.paid_price_usd;
    });
    return {
      won,
      doller
    };
  }
  // 환불 보증금 리스트
  get refundDepositList() {
    const re: any = [];
    this.reservation.deposit.filter((row: any) => {
      if (row.payment_status === 'refund') {
        re.push(row);
      }
    });
    return re;
  }
  // 환불 상태의 총 결제금액
  get getTotalRefundPrice() {
    const data: any = {
      price: 0,
      price_usd: 0,
    };
    for (const pay of this.refundDepositList) {
      data.price = data.price + pay.paid_price;
      data.price_usd = data.price_usd + pay.paid_price_usd;
    }
    return data;
  }
}
